import { CSSProperties } from 'react';
import styled from 'styled-components';
import { renderToStaticMarkup } from 'react-dom/server';
import { CustomNavigationMeta } from 'app/routes';
import config from '../styles/global';

export const CheckGlobalNavHTMLTree = () => {
  const title = [...document.querySelectorAll('title')].filter(
    ({ textContent }) => textContent === 'Service NSW - Home',
  )[0];
  const brandingArea = title?.parentElement?.parentElement;
  const navigation = brandingArea?.parentElement?.parentElement;

  // If this test fails then the pattern we use to ascertain the branding section in global nav has changed
  // and will have to be adjusted within mountCustomBranding.tsx
  const titleExists = !!title;
  const brandingAreaIsAnchor = brandingArea?.nodeName === 'A';
  const isWrappedInNav = navigation?.getAttribute('data-ga4-section-id') === 'header_global';
  if (!(titleExists && brandingAreaIsAnchor && isWrappedInNav)) throw Error('Global Nav HTML structure has changed.');
};

const mobileBreakpoint = `${config.breakpoints.small.value}px`;

const LogoWrapper = styled.div<{ position: 'left' | 'right' }>`
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: ${({ position }) => (position === 'left' ? 'flex-start' : 'flex-end')};
  margin-right: ${({ position }) => (position === 'left' ? '10px' : '0')};
  @media (max-width: ${mobileBreakpoint}) {
    display: ${({ position }) => (position === 'right' ? 'none' : 'flex')};
    justify-content: center;
  }
`;

const generateReplacementContent = ({ logoAltText, logoImagePath, logoAnchorUrl }: CustomNavigationMeta) => {
  const wrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '10px',
    flexGrow: '1',
    margin: '5px',
  } as CSSProperties;

  const replacementContent = document.createElement('div');
  const replacementLogos = (
    <>
      <LogoWrapper position="left">
        <a href={logoAnchorUrl} style={{ display: 'flex' }} role="link">
          <img id="custom-logo-nav" src={logoImagePath} aria-label={logoAltText} />
        </a>
      </LogoWrapper>
      <LogoWrapper position="right">
        <a href="https://www.service.nsw.gov.au" style={{ display: 'flex' }} role="link">
          <img id="snsw-logo-nav" src={'./sbr-assets/agency-logos/snsw.png'} aria-label="Service NSW Logo" />
        </a>
      </LogoWrapper>
    </>
  );
  for (const [key, value] of Object.entries(wrapperStyles)) {
    replacementContent.style[key] = value;
  }
  replacementContent.innerHTML = renderToStaticMarkup(replacementLogos).toString();
  return replacementContent;
};

export const mountCustomBranding = (logoMeta: CustomNavigationMeta) => {
  const brandingElement = [...document.getElementsByTagName('title')].filter(
    (node) => node.innerHTML === 'Service NSW - Home',
  )[0]?.parentElement.parentElement;

  if (brandingElement) {
    const defaultContent = brandingElement;
    const brandingWrapperElement = brandingElement?.parentElement;
    const defaultWrapperDisplayValue = brandingWrapperElement?.style.display;
    const replacementContent = generateReplacementContent(logoMeta);

    brandingWrapperElement.setAttribute('style', 'display:flex;');
    brandingElement.replaceWith(replacementContent);

    // The return function reverts it to the original branding.
    return () => {
      replacementContent?.replaceWith(defaultContent);
      brandingWrapperElement.setAttribute('style', `display:${defaultWrapperDisplayValue ?? 'block'};`);
    };
  }
  return () => undefined;
};
